import { getFrontendUrl } from "../../helpers/utils";

export function getWebsiteRichSnippet() {
  return {
    "@type": "WebSite",
    "@id": getFrontendUrl("/#website"),
    url: getFrontendUrl("/"),
    name: "Drew Doggett",
    publisher: {
      "@id": getFrontendUrl("/#person")
    }
  };
}
