import React from "react";
import { Media } from "../types/Media";
import { getImageUrl } from "../helpers/getImageUrl";
import { getFrontendUrl } from "../helpers/utils";
import Head from "next/head";
import { getWebsiteRichSnippet } from "../helpers/rich-snippets/website";
import { getAboutRichSnippet } from "../helpers/rich-snippets/about";
import { getOrganisationRichSnippet } from "../helpers/rich-snippets/organisation";
import { useCommonData } from "../context/CommonDataContext";

export function SEOTags(props: {
  title: string;
  description?: string;
  keywords?: string;
  type?: "website" | "article";
  url: string | null;
  featuredImage?: Media | null;
  image?: string;
  publishedTime?: string;
  tags?: Array<{
    name: string;
  }>;
  richSnippets?: Record<string, unknown>;
}) {
  const website = getWebsiteRichSnippet();
  const person = getAboutRichSnippet();
  const organisation = getOrganisationRichSnippet();
  const commonData = useCommonData();

  let featuredImage = props.featuredImage;
  if (!featuredImage && commonData.settings.main_featured_image) {
    featuredImage = commonData.settings.main_featured_image;
  }

  return (
    <Head>
      <title>{props.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      {props.description && (
        <meta name="description" content={props.description} />
      )}
      {props.keywords && <meta name="keywords" content={props.keywords} />}
      <meta property="og:title" content={props.title} />
      <meta property="og:type" content={props.type || "article"} />
      {props.url && <meta property="og:url" content={props.url} />}
      {props.publishedTime && (
        <meta
          property="og:article:published_time"
          content={props.publishedTime}
        />
      )}
      {props.image ? (
        <meta property="og:image" content={getFrontendUrl(props.image)} />
      ) : (
        featuredImage && (
          <>
            <meta property="og:image" content={getImageUrl(featuredImage)} />
            <meta
              property="og:image:width"
              content={`${featuredImage.width}`}
            />
            <meta
              property="og:image:height"
              content={`${featuredImage.height}`}
            />
          </>
        )
      )}
      {props.tags &&
        props.tags.map((tag, index) => {
          return (
            <meta
              property="og:tag"
              content={tag.name}
              key={`seo-tag--${index}`}
            />
          );
        })}
      {props.richSnippets && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@graph": [website, organisation, person, props.richSnippets],
            }),
          }}
        />
      )}
    </Head>
  );
}
