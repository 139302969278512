import { CommonData } from "../types/CommonData";
import Header from "../components/Header";
import { NextPage } from "next";
import { getCommonData } from "../helpers/common-data";
import { getAnnouncements } from "../helpers/getAnnouncements";
import { Announcement } from "../types/Announcement";
import { Spacing } from "../helpers/layout";
import { Wrapper } from "../components/Wrapper";
import { css } from "styled-components";
import { useUserState } from "../context/UserContext";
import { AlreadyAuthenticated } from "../components/AlreadyAuthenticated";
import Footer from "../components/Footer";
import { useResponsive } from "../context/ResponsiveContext";
import { NotLoggedInForm } from "../components/AccountForm";
import { getFrontendUrl } from "../helpers/utils";
import { SEOTags } from "../components/SEOTags";
import { getRichSnippet } from "../helpers/rich-snippets/static";

export const PageLogin: NextPage<{
  commonData: CommonData;
  announcements: Announcement[];
}> = (props) => {
  const { isTablet } = useResponsive();
  const { isAuthenticated } = useUserState();

  const data = {
    title: "Log In",
    keywords: "log in",
    description:
      "Log in to your profile on Drew Doggett’s website to create a mockup of your space, view saved works, access your wishlist, and more. ",
    url: getFrontendUrl(`/login`),
    about: "Log in to access your account on Drew Doggett’s website",
  };

  return (
    <>
      <SEOTags
        title={data.title}
        keywords={data.keywords}
        description={data.description}
        url={data.url}
        richSnippets={getRichSnippet({
          name: data.title,
          slug: "login",
          keywords: data.keywords,
          description: data.description,
          about: data.about,
        })}
      />
      <Header announcements={props.announcements} />
      <Wrapper>
        <div
          css={css`
            margin: 0 auto;
            padding: ${Spacing.xxl} 0;
            max-width: ${isTablet ? "600px" : "none"};
            text-align: center;
          `}
        >
          {!isAuthenticated ? (
            <div
              css={css`
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
              `}
            >
              <NotLoggedInForm signIn />
            </div>
          ) : (
            <div
              css={css`
                margin-bottom: ${Spacing.xxl};
              `}
            >
              <AlreadyAuthenticated />
            </div>
          )}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

PageLogin.getInitialProps = async ({ req }) => {
  const commonData = await getCommonData(req);
  const announcements = await getAnnouncements("ALL");
  return {
    commonData: commonData,
    announcements,
  };
};
export default PageLogin;
