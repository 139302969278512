import { getFrontendUrl } from "../utils";

export function getAboutRichSnippet() {
  return {
    "@type": "Person",
    "@id": getFrontendUrl("/#person"),
    name: "Drew Doggett",
    description:
      "Drew Doggett is an award-winning photographer and director whose work celebrates people, culture, diversity, and our natural world. He travels the globe in search of untold stories and is relentless in his pursuit of exploring the most unique and extraordinary residents of this Earth. His photographic work can be found in private and public collections globally, notably the Smithsonian African Art Museum (DC) & the Mariners’ Museum (VA), and he has received over 90 awards and honors for his images including a distinction from the Royal Photographic Society. ",
    birthPlace: {
      "@type": "Place",
      address: {
        addressRegion: "DC",
        addressLocality: "Washington",
        addressCountry: "United States"
      }
    },
    email: "info@drewdoggett.com",
    alumniOf: "Vanderbilt University"
  };
}
