import { getFrontendUrl } from "../utils";

export function getOrganisationRichSnippet() {
  return {
    "@type": "Organization",
    "@id": getFrontendUrl("/#organisation"),
    name: "Drew Doggett Photography",
    description:
      "Artist studio creating limited edition prints and short films that tell the global stories of extraordinary places, cultures, and animals.",
    address: {
      addressRegion: "SC",
      addressLocality: "Charleston",
      addressCountry: "United States",
    },
    founder: {
      "@id": getFrontendUrl("/#person"),
      url: getFrontendUrl("/about-drew"),
    },
    foundingDate: "2009",
    foundingLocation: {
      "@type": "Place",
      address: {
        addressRegion: "NY",
        addressLocality: "New York",
        addressCountry: "United States",
      },
    },
    email: "info@drewdoggett.com",
    legalName: "Drew Doggett Photography LLC",
    telephone: "+18439363592",
    image: getFrontendUrl("/wp-content/themes/drew-doggett/signature/logo.png"),
  };
}
