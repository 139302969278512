import { getFrontendUrl } from "../../helpers/utils";

type StaticPage = {
  name: string;
  slug: string;
  about: string;
  description: string;
  keywords: string;
};

export function getRichSnippet(page: StaticPage) {
  return {
    "@type": "WebPage",
    "@id": getFrontendUrl(`/#${page.slug}`),
    name: page.name,
    url: getFrontendUrl(`/${page.slug}`),
    about: page.about,
    description: page.description,
    keywords: page.keywords,
    isFamilyFriendly: true,
    inLanguage: "en-US",
    isPartOf: {
      "@id": getFrontendUrl("/#website"),
    },
  };
}
